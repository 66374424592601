import loading from "../images/iconsGif/loader.gif";
import React from "react";

export const LoadingPage = () => {
    return (
        <div
            className="flex flex-col justify-center items-center min-h-screen  bg-gradient-to-b from-purple-600 to-purple-900">
            <img src={loading} className="w-1/3" alt="logo"/>

            <h1 className="text-2xl font-bold" dir="ltr">M7MMAD OMAR - PARMAGE TECH</h1>
            <h2 className="text-2xl font-bold" dir="ltr">LOADING...</h2>
        </div>
    );
}