import {motion} from "framer-motion";

export const SkillCard = (props: {
    skillsDash: any;
    totalSkills: any;
    subTitle?: any;
    title: any;
}) => {
    return (
        <motion.div className="w-80 bg-white/20 rounded-xl p-3 m-4 shadow-2xl shadow-black/80"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                    data-aos-mirror="true"
                    data-aos-once="false">
            <div className="flex justify-between">

                <h3 className="font-bold text-2xl ml-3 text-slate-300"
                    data-aos="fade-down"
                    data-aos-offset="100"
                    data-aos-easing="linear"
                    data-aos-duration="700"
                    data-aos-delay="600"
                    data-aos-mirror="true"
                    data-aos-once="false">
                    {
                        props.title
                    }
                    <span className="text-sm font-normal mx-1">
                        {
                            props.subTitle
                        }
                    </span>
                </h3>
                <span>
                    {props.totalSkills}
                </span>
            </div>
            <div dir="ltr">
                {
                    props.skillsDash
                }
            </div>

        </motion.div>
    );
}
