import {motion} from "framer-motion";
import mobileAppService from "../images/Service/imgMobileService.png";
import patternDots from "../images/patternDots.png";
import laptopService from "../images/Service/imgLabtopService.png";
import dbService from "../images/Service/imgDbService.png";
import footerService from "../images/Service/imgFooterService.png";
import shopService from "../images/Service/imgShopService.png";
import designService from "../images/Service/imgDesignService.png";
import sitService from "../images/Service/imgAppLabtopService.png";
import {CardService} from "../components/service/CardService";
import {AiOutlineDoubleLeft} from "react-icons/ai";
import {GoDeviceMobile} from "react-icons/go";
import {MdLaptopChromebook, MdOutlineSettingsSystemDaydream} from "react-icons/md";
import {FaDatabase, FaPaintBrush} from "react-icons/fa";
import {SiElectron} from "react-icons/si";
import {TimeLineUI} from "../components/material-ui/TimeLineUI";
import {Link} from "react-router-dom";


const moveRightLeft = {
    hidden: {opacity: 0},
    show: {
        opacity: [0.5, 1, 0.5],
        x: ["0%", "120%", "0%"],
        transition: {
            duration: 5,
            ease: "easeInOut",
            repeat: Infinity,
        }
    }
}

const moveRightLeftImageFooter = {
    hidden: {opacity: 0},
    show: {
        opacity: [0.8, 0.5, 0.8],
        x: ["0%", "10%", "-10%", "0%"],
        transition: {
            duration: 20,
            ease: "easeInOut",
            repeat: Infinity,
        }
    }
}

const Services = () => {

    return (
        <motion.div
            initial={{x: "100%", opacity: 0}}
            animate={{x: "0%", opacity: 1}}
            transition={{duration: 0.75, ease: "easeOut"}}
            exit={{opacity: 1}}
            className="min-h-screen pt-20 pattern-services text-center select-none overflow-x-hidden">
            <p className={` my-3 text-xl font-bold py-1 px-10 w-fit mx-auto rounded-xl bg-black/60`}>متوفر قريباً</p>
            <h1 className="text-center text-xl sm:text-xl md:text-4xl flex flex-col space-y-7">

                <p
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-offset="20"
                    data-aos-duration="2000"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    className="flex  items-center mx-auto">
                    <motion.span
                        variants={moveRightLeft}
                        initial="hidden"
                        animate="show">
                        <AiOutlineDoubleLeft></AiOutlineDoubleLeft>
                    </motion.span>
                    <span>
					عندك فكرة ببالك وحابب تعملها حقيقة؟🤔
				</span>
                </p>


                <p
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-offset="100"
                    data-aos-duration="2500"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    className="flex items-center mx-auto">
                    <motion.span
                        variants={moveRightLeft}
                        initial="hidden"
                        animate="show">
                        <AiOutlineDoubleLeft></AiOutlineDoubleLeft>
                    </motion.span>
                    بدك تعمل تطبيق لعملك ومحتار كيف؟🤩
                </p>

                <p
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-offset="100"
                    data-aos-duration="3000"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    className="flex items-center mx-auto">
                    <motion.span
                        variants={moveRightLeft}
                        initial="hidden"
                        animate="show">
                        <AiOutlineDoubleLeft></AiOutlineDoubleLeft>
                    </motion.span>
                    <div className="flex flex-wrap">
                        <span>&nbsp; الحل عنا, فقط انظر إلى&nbsp;</span>
                        <strong
                            data-aos="fade-right"
                            data-aos-easing="linear"
                            data-aos-offset="100"
                            data-aos-duration="3000"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            className="decoration-2 underline underline-offset-4 decoration-purple-600 mx-auto sm:mx-3">
                            خدماتنا الرائعة
                        </strong>
                    </div>
                </p>
            </h1>


            {/*  Start Section Service  */}
            <section
                className="py-32 mx-5 md:mx-[10%] xl:mx-[20%] space-y-20 flex flex-col space-y-40   ">


                {/*  Start Card Service */}
                <CardService
                    title="تطبيقات الهاتف المحمول"
                    isLeftImage={true}
                    mainImage={mobileAppService}
                    patternImage={patternDots}
                    icon={<GoDeviceMobile className="w-5 h-5 mr-4"></GoDeviceMobile>}
                    body="
يزداد كل يوم مستخدمي الهواتف الذكية كما أن عددهم الآن يفوق عدد مستخدمي أجهزة الكمبيوتر، لذلك تحرص الشركات على تصميم وبرمجة تطبيقات الهواتف الذكية التي تفيد مجال عملهم للتواصل بشكل أسرع وأسهل مع عملائها وتحقيق نتائج أكثر في الترويج والتسويق لأعمالهم.
إذا كنت تبحث عن تصميم وبرمجة تطبيقات الهواتف الذكية لمشروعك، فأنت في المكان المناسب ، فإننا نفخر بتقديم أفضل الحلول البرمجية لأننا نعمل بأحدث الأساليب ونحرص على التطوير المستمر ونختار أفضل بيئات العمل (FrameWork).
"
                />
                {/*  End Card Service */}

                {/*  Start Card Service */}
                <CardService
                    title="مواقع تجارية"
                    isLeftImage={false}
                    mainImage={shopService}
                    patternImage={patternDots}
                    icon={<MdLaptopChromebook className="w-5 h-5 mr-4"></MdLaptopChromebook>}
                    body="
نساعد العملاء عن طريق إنشاء متاجر إلكترونية تجذب الزوار وتجعلهم عملاء محتملين. كما يمكننا إنشاء متجر إلكتروني جديد لك أفضل من متجرك الحالي أو الحصول على تطوير مميز لمتجرك ، خدماتنا تشمل تصميم المتاجر الإلكترونية وبرمجتها وتقديم الدعم الفني المستمر للمتاجر الإلكترونية …..وغيرها من الخدمات.
"
                />
                {/*  End Card Service */}


                {/*  Start Card Service */}
                <CardService
                    title="مدونات إلكترونية"
                    isLeftImage={true}
                    mainImage={laptopService}
                    patternImage={patternDots}
                    icon={<SiElectron className="w-5 h-5 mr-4"></SiElectron>}
                    body="
 يُمكنك الآن طلب تصميم موقع إلكتروني جديد، أو تطوير الموقع الحالي الخاص بك إذا كنت تمتلك موقعًا بالفعل.
ليس عليك سوى إخبارنا بكل التفاصيل التي تحتاجها وسنقدم لك مجموعة مميزة من التصميمات لاختيار الشكل المناسب وسنساعدك في ذلك بخبرتنا، نحن لا نعمل بشكل عشوائي وإنما نعمل وفق استراتيجيات وخطط مدروسة بدقة.
                    "
                />
                {/*  End Card Service */}

                {/*  Start Card Service */}
                <CardService
                    title="تصميم قواعد البيانات"
                    isLeftImage={false}
                    mainImage={dbService}
                    patternImage={patternDots}
                    icon={<FaDatabase className="w-5 h-5 mr-4"></FaDatabase>}
                    body="
توفر لك قاعدة البيانات المصممة بشكل صحيح إمكانية الوصول إلى المعلومات المحدثة الدقيقة. لأن التصميم الصحيح يعد ضرورياً لتحقيق أهدافك في العمل مع قاعدة البيانات واستثمار الوقت المطلوب لمعرفة مبادئ التصميم المنطقي الجيد. في النهاية، من الأرجح أن تتوفر لك قاعدة بيانات تلبي احتياجاتك ويمكنها بسهولة احتواء التغيير.
"
                />
                {/*  End Card Service */}


                {/*  Start Card Service */}
                <CardService
                    title="اتمتة الأنظمة الإدارية"
                    isLeftImage={true}
                    mainImage={sitService}
                    patternImage={patternDots}
                    icon={<MdOutlineSettingsSystemDaydream className="w-5 h-5 mr-4 "></MdOutlineSettingsSystemDaydream>}
                    body="
للشركات والمؤسسات حيث نعمل على توفير خدمات مُتكاملة تُلبي إحتياجات كل شركة سواء كانت كبيرة أو صغيرة في إدارة كل نواحي العمل، وذلك من خلال تصميم واجهة النظام وبرمجتها وإختبار طريقة عملها مع تشغيل النظام بالكامل وتوفير تحليل البيانات التي تحدث على النظام بالكامل.
وبذلك تكون قد امتلكت في شركتك أفضل انظمة ادارة الشركات والمؤسسات تستطيع من خلاله توفير بيئة عمل قوية وآمنة وتوفير نظام تواصل جيد بين العاملين وتحليل كافة البيانات.
"
                />
                {/*  End Card Service */}

                {/*  Start Card Service */}
                <CardService
                    title="هوية بصرية متكاملة"
                    isLeftImage={false}
                    mainImage={designService}
                    patternImage={patternDots}
                    icon={<FaPaintBrush className="w-5 h-5 mr-4"></FaPaintBrush>}
                    body="
ان تصميمات الجرافيك سواء للأفراد أو الشركات يعتبر من الأدوات التسويقية الهامة و الضرورية في العصر الحديث.
ولأن التكنولوجيا في تطور دائم فأيضا الوسائل و الأدوات التي نستخدمها في عمليات التسويق يجب أن تكون ملائمة لهذا التطور.
لذلك يعتبر التصميم الجرافيكي عنصر هام من عناصر التسويق سواء التسويق العادي او التسويق الالكتروني.
حيث تظهر عبقرية الجرافيك ديزاين في التسويق العادي من خلال ” الكروت الشخصية – تصميمات علي الاكواب – الفلايرز – البروشورز …
"
                />
                {/*  End Card Service */}


            </section>
            {/* End Section Service  */}


            {/* Start Last Section Service*/}
            <section>
                <h2
                    data-aos="fade-left"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-duration="2500"
                    className={`text-xl  md:text-4xl mx-4 font-bold leading-loose tracking-wide border-x-8 rounded-md border-purple-600`}>
                    بعد رؤية خدماتنا, بالتأكيد يجب علينا إطلاعكم على طريقة تنفيذ المشاريع
                </h2>


                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`1`}
                    title={`تحديد الفكرة ودراستها:`}
                    body={`تعتبر أكثر الخطوات أهمية، حيث نقوم بتحليل فكرة المشروع الأساسية ودراستها لأنها الأساس الذي يدور حوله كل شيء`}
                />
                {/* End TimeLine Item Service */}


                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`2`}
                    title={`تصميم المشروع:`}
                    body={`نحرص على بناء تصميمات مميزة للمشاريع لنضمن تجربة مستخدم لا مثيل لها، ويكتمل إبداع فريقنا عند حرصه على بناء مشاريع تجذب المستخدمين لمنتجاتك أو خدماتك المعروضة  مرارًا وتكرارًا`}
                />
                {/* End TimeLine Item Service */}

                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`3`}
                    title={`مرحلة البرمجة:`}
                    body={`نقدم حلول عالية الجودة باستخدام أحدث التقنيات المتطورة حتى تحصل في النهاية على مشروع فعّال ومتكامل بأقل التكاليف`}
                />
                {/* End TimeLine Item Service */}

                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`4`}
                    title={`اختبار التطبيق قبل الإطلاق`}
                    body={`نحن هنا لتوفير أفضل الخدمات لك، ونضمن لك التزامنا بوعودنا فيما يخص حصولك على مشروع ذات مواصفات قياسية ولا يوجد به أية عيوب`}
                />
                {/* End TimeLine Item Service */}

                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`5`}
                    title={`إطلاق المشروع:`}
                    body={`نقوم بإطلاق مشروعك على الإنترنت بكل ثقة في النجاح لأننا متأكدين من جودة أعمالنا، كما نبقى معك طوال مدة الإطلاق والنشر لنساعدك على تحقيق أهدافك`}
                />
                {/* End TimeLine Item Service */}

                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`6`}
                    title={`التسويق للتطبيق:`}
                    body={`بعد الإطلاق سيقوم خبراء التسويق لدينا بوضع أفضل خطط التسويق لمساعدتك في تطوير أعمالك وإقناع العميل المستهدف بشراء المنتج أو الخدمة التي تقدمها من خلال التطبيق`}
                />
                {/* End TimeLine Item Service */}


                <h3
                    data-aos="fade-left"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-duration="2500"
                    className={`text-xl  md:text-4xl mx-4 mt-20 font-bold leading-loose tracking-wide border-x-8 rounded-md border-purple-600`}>
                    ماذا تنتظر! اطلب الآن مشروعك الخاص واستمتع بخدماتنا المميزة
                    <span
                        data-aos="fade-right"
                        data-aos-offset="50"
                        data-aos-easing="linear"
                        data-aos-duration="4000"
                        className="transition duration-500 ease-linear  px-2 my-2 hover:bg-purple-600/30 rounded-xl w-fit underline decoration-dashed decoration-4 underline-offset-8 decoration-purple-500 font-medium">
                        <Link to="/contact">
                            تواصل معنا</Link>
                    </span>
                </h3>


                {/* Start TimeLine Service */}


                {/* End TimeLine Service */}


                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <motion.img
                    variants={moveRightLeftImageFooter}
                    initial="hidden"
                    animate="show"
                    src={footerService} alt="footer image"
                    className={`relative bottom-0 w-[80%] mx-auto opacity-80 mt-32`}/>
            </section>
            {/* End Last Section Service*/}


        </motion.div>
    );
};

export default Services;
