import {motion} from "framer-motion";


export const RedO = () => {

	const draw = {
		hidden: {pathLength: 0, opacity: 0},
		visible: (i: number) => {
			const delay = 1 + i * 1;
			return {
				pathLength: 1,
				opacity: 1,
				transition: {
					pathLength: {delay, type: "spring", duration: 5, bounce: 0},
					opacity: {delay, duration: 5}
				}
			};
		}
	};

	return (
		<>
			<motion.svg
				height="100%"
				viewBox="0 0 600 600"
				initial="hidden"
				animate="visible"
				className="mt-8 blur-md w-full"
			>
				<motion.circle
					cx="100"
					cy="100"
					r="80"
					stroke="#ff0055"
					variants={draw}
					custom={1}
				/>
			</motion.svg>


		</>
	);
}