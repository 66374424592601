import "../index.css"
import {DrawInHomePage} from "../components/DrawInHomePage";
import {ContactLinks} from "../components/ContactLinks";
import {motion} from "framer-motion";
import {RightToCenter} from "../components/effect/RightToCenter";
import {CardWhite} from "../components/CardWhite";
import myImage from "../images/m7mmad-omar.jpg";
import cat from "../images/iconsGif/cat.gif";


const Home = () => {
	const myName = "محمد عمر حاج حمدو";
	const myJobEn = "Laravel Full Stack Developer";
	const myJobAr = "مطور مواقع ويب";
    const myText = "طالب جامعي في كلية الهندسة المعلوماتية - الجامعة الدولية للعلوم والنهضة " +
        "لدي خبرة ضمن Laravel, ReactJs, تواصلوا معنا عبر ";
	// effect
	// const bToC = BottomToCenter;

	// @ts-ignore
	return (
		<div>
			<main className="min-h-screen py-32  relative overflow-x-hidden overflow-y-hidden pattern-main">
				{/* drawingShapes in home page */}
				<DrawInHomePage/>
				<div className="container absolute -top-28 right-10  w-[80%] md:w-[90%] xl:w-[100%] xl:mr-20 mt-36 md:mt-22 xl:mt-10">
					<section>
						{/* My Image */}
						<article className="avatar md:w-1/2 xl:w-1/3 md:right-1/4 xl:right-0 xl:top-48">
								<motion.div
									variants={RightToCenter}
									initial="hidden"
									animate="show"
									className="w-[70%] mb-10 rounded-full  mx-auto ring ring-purple-800 ring-offset-base-100 ring-offset-2">
									{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
									<img src={myImage} alt="My Image"/>
								</motion.div>
						</article>

						{/* About me */}
						<CardWhite title={myName} desAr={myJobAr} desEn={myJobEn} text={myText} />


					</section>

					{/*	contact my */}
					<article>
						<div className="mt-10 xl:mt-0">
							<ContactLinks/>
						</div>
					</article>

				</div>

			</main>


				{/* image cat */}
			{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img src={cat} alt="Cat Image" className="hidden xl:block absolute -bottom-7 w-32 md:w-64"
                 data-aos="fade-up"
                 data-aos-delay="50"
                 data-aos-duration="1400"
                 data-aos-easing="ease-in-out"
                 data-aos-mirror="true"
                 data-aos-once="false"
                 data-aos-anchor-placement="bottom"/>
            <span className="hidden xl:block absolute -bottom-6 w-32 w-64 border-t-2 border-purple-500">&nbsp; </span>
		</div>
	);
}


export default Home;