import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';


const Experience = () => {
    return (
        <div
            className={` min-h-screen overflow-x-hidden overflow-y-hidden relative flex justify-center items-center py-20 bg-experience `}>
            {/* Start show for md screen*/}
            <div
                data-aos="fade-up"
                data-aos-duration="3000"
                className={`hidden md:block  `}>
                <Timeline position="alternate" className={`max-w-[80vw] `}>
                    <TimelineItem
                        data-aos="fade-right"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000">
                        <TimelineContent sx={{py: '12px', px: 2}}>
                            <h2
                                data-aos="fade-left"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-bold border-r-8 border-b-4 w-fit border-purple-800 p-3 rounded-2xl bg-black/40`}>
                                منظمة مداد التعليمية
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-medium leading-loose`}>
                                ضمن تدريب 1000 مبرمج عربي حصلت على عدة خبرات في مجال تحليل قواعد البيانات وإدارتها
                                وتطوير مواقع الويب للواجهة الأمامية وأيضا الواجهة الخلفية للموقع باستخدام
                                الأدوات الأساسية لتطوير المواقع ولغة أيضا إطار العمل Laravel
                            </p>
                        </TimelineContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"/>
                            <TimelineConnector/>
                        </TimelineSeparator>

                        <TimelineOppositeContent
                            sx={{m: 'auto 0'}}
                            align="center"
                            variant="body2"
                            color="text.white"
                        >
                            من 06 - 2022
                            <br/>
                            حتى 12 - 2022
                        </TimelineOppositeContent>
                    </TimelineItem>

                    <TimelineItem
                        data-aos="fade-left"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000">
                        <TimelineContent sx={{py: '12px', px: 2}}>
                            <h2
                                data-aos="fade-right"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-bold border-r-8 border-b-4 w-fit border-purple-800 p-3 rounded-2xl bg-black/40`}>
                                الجامعة الدولية للعلوم والنهضة
                            </h2>
                            <p
                                data-aos="fade-right"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-medium leading-loose`}>
                                ادرس في كلية الهندسة المعلوماتية المستوى الثالث تعلمت عدة خوارزميات
                                للبحث وتخزين البيانات, أيضا تعلمت العديد من اللغات البرمجية وإدارة المشاريع النظرية
                                والعملية
                                بالإضافة إلى
                                الشبكات السلكية والاسلكية والربط بينهم
                                والكثير من الأساسيات
                            </p>
                        </TimelineContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"/>
                            <TimelineConnector/>
                        </TimelineSeparator>

                        <TimelineOppositeContent
                            sx={{m: 'auto 0'}}
                            align="right"
                            variant="body2"
                            color="text.white"
                        >
                            من 2019
                            <br/>
                            حتى الآن
                        </TimelineOppositeContent>
                    </TimelineItem>

                    <TimelineItem
                        data-aos="fade-right"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000">
                        <TimelineContent sx={{py: '12px', px: 2}}>
                            <h2
                                data-aos="fade-left"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-bold border-r-8 border-b-4 w-fit border-purple-800 p-3 rounded-2xl bg-black/40`}>
                                منصات تعليمية إلكترونية
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-medium leading-loose`}>
                                شاهدت العديد من الدورات التدريبية على منصات موثوقة مثل يوديمي, يوتيوب, لينكدإن
                                في مجالات عديدة منها تطوير المواقع وبرمجة تطبيقات سطح المكتب
                                وإدارة المشاريع البرمجية والخوارزميات الرياضية والنهدسية
                                أيضا في مجال قواعد البيانات وتحليلها
                            </p>
                        </TimelineContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"/>
                            <TimelineConnector/>
                        </TimelineSeparator>

                        <TimelineOppositeContent
                            sx={{m: 'auto 0'}}
                            align="right"
                            variant="body2"
                            color="text.white"
                        >
                            من 2017
                            <br/>
                            إلى الأبد 🥲
                        </TimelineOppositeContent>
                    </TimelineItem>


                    <TimelineItem
                        data-aos="fade-left"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000"
                    >
                        <TimelineContent sx={{py: '12px', px: 2}}>
                            <h2
                                data-aos="fade-right"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-bold border-r-8 border-b-4 w-fit border-purple-800 p-3 rounded-2xl bg-black/40`}>
                                منظمة أورنج التعليمية
                            </h2>
                            <p
                                data-aos="fade-right"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-medium leading-loose`}>
                                من المنظمات المعروفة طبعا تعلمت صيانة الجوالات المحمولة وطريقة فحصها واختبار الأداء
                                والأهم من هذا هو معرفة العطل وسبب المشكلة 🤔
                            </p>
                        </TimelineContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"/>
                            <TimelineConnector/>
                        </TimelineSeparator>

                        <TimelineOppositeContent
                            sx={{m: 'auto 0'}}
                            align="right"
                            variant="body2"
                            color="text.white"
                        >
                            من 6 - 2019
                            <br/>
                            حتى 10 - 2019
                        </TimelineOppositeContent>
                    </TimelineItem>


                    <TimelineItem
                        data-aos="fade-up"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000">
                        <TimelineContent sx={{py: '12px', px: 2}}>
                        </TimelineContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"/>
                            <TimelineConnector/>
                        </TimelineSeparator>

                        <TimelineOppositeContent>
                        </TimelineOppositeContent>
                    </TimelineItem>

                </Timeline>
            </div>
            {/* End show for md screen*/}

            {/* Start show for mobile screen*/}
            <div
                data-aos="fade-up"
                data-aos-duration="3000"
                className={`block md:hidden  `}>
                <Timeline position="left" className={`max-w-[100vw] `}>
                    <TimelineItem
                        data-aos="fade-right"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000">
                        <TimelineContent sx={{py: '12px', px: 2}}>
                            <h2
                                data-aos="fade-left"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-bold border-r-8 border-b-4 w-[76vw] border-purple-800 p-3 rounded-2xl bg-black/40`}>
                                منظمة مداد التعليمية
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-medium leading-loose`}>
                                ضمن تدريب 1000 مبرمج عربي حصلت على عدة خبرات في مجال تحليل قواعد البيانات وإدارتها
                                وتطوير مواقع الويب للواجهة الأمامية وأيضا الواجهة الخلفية للموقع باستخدام
                                الأدوات الأساسية لتطوير المواقع ولغة أيضا إطار العمل Laravel
                            </p>
                        </TimelineContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"/>
                            <TimelineConnector/>
                        </TimelineSeparator>

                    </TimelineItem>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        align="center"
                        variant="body2"
                        color="text.white"
                    >
                        من 06 - 2022
                        <br/>
                        حتى 12 - 2022
                    </TimelineOppositeContent>


                    <TimelineItem
                        data-aos="fade-left"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000">
                        <TimelineContent sx={{py: '12px', px: 2}}>
                            <h2
                                data-aos="fade-right"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-bold border-r-8 border-b-4 w-[76vw] border-purple-800 p-3 rounded-2xl bg-black/40`}>
                                الجامعة الدولية للعلوم والنهضة
                            </h2>
                            <p
                                data-aos="fade-right"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-medium leading-loose`}>
                                ادرس في كلية الهندسة المعلوماتية المستوى الثالث تعلمت عدة خوارزميات
                                للبحث وتخزين البيانات, أيضا تعلمت العديد من اللغات البرمجية وإدارة المشاريع النظرية
                                والعملية
                                بالإضافة إلى
                                الشبكات السلكية والاسلكية والربط بينهم
                                والكثير من الأساسيات
                            </p>
                        </TimelineContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"/>
                            <TimelineConnector/>
                        </TimelineSeparator>

                    </TimelineItem>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        align="right"
                        variant="body2"
                        color="text.white"
                    >
                        من 2019
                        <br/>
                        حتى الآن
                    </TimelineOppositeContent>

                    <TimelineItem
                        data-aos="fade-right"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000">
                        <TimelineContent sx={{py: '12px', px: 2}}>
                            <h2
                                data-aos="fade-left"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-bold border-r-8 border-b-4 w-[76vw] border-purple-800 p-3 rounded-2xl bg-black/40`}>
                                منصات تعليمية إلكترونية
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-medium leading-loose`}>
                                شاهدت العديد من الدورات التدريبية على منصات موثوقة مثل يوديمي, يوتيوب, لينكدإن
                                في مجالات عديدة منها تطوير المواقع وبرمجة تطبيقات سطح المكتب
                                وإدارة المشاريع البرمجية والخوارزميات الرياضية والنهدسية
                                أيضا في مجال قواعد البيانات وتحليلها
                            </p>
                        </TimelineContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"/>
                            <TimelineConnector/>
                        </TimelineSeparator>

                    </TimelineItem>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        align="right"
                        variant="body2"
                        color="text.white"
                    >
                        من 2017
                        <br/>
                        إلى الأبد 🥲
                    </TimelineOppositeContent>


                    <TimelineItem
                        data-aos="fade-left"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000"
                    >
                        <TimelineContent sx={{py: '12px', px: 2}}>
                            <h2
                                data-aos="fade-right"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-bold border-r-8 border-b-4 w-[76vw] border-purple-800 p-3 rounded-2xl bg-black/40`}>
                                منظمة أورنج التعليمية
                            </h2>
                            <p
                                data-aos="fade-right"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                data-aos-duration="3000"
                                className={`text-right text-2xl font-medium leading-loose`}>
                                من المنظمات المعروفة طبعا تعلمت صيانة الجوالات المحمولة وطريقة فحصها واختبار الأداء
                                والأهم من هذا هو معرفة العطل وسبب المشكلة 🤔
                            </p>
                        </TimelineContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"/>
                            <TimelineConnector/>
                        </TimelineSeparator>

                    </TimelineItem>
                    <TimelineOppositeContent
                        sx={{m: 'auto 0'}}
                        align="right"
                        variant="body2"
                        color="text.white"
                    >
                        من 6 - 2019
                        <br/>
                        حتى 10 - 2019
                    </TimelineOppositeContent>


                    <TimelineItem
                        data-aos="fade-up"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000">
                        <TimelineContent sx={{py: '12px', px: 2}}>
                        </TimelineContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary"/>
                            <TimelineConnector/>
                        </TimelineSeparator>


                    </TimelineItem>
                    <TimelineOppositeContent>
                    </TimelineOppositeContent>
                </Timeline>
            </div>
            {/* End show for mobile screen*/}

        </div>
    );
};

export default Experience;