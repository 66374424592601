export const BottomToCenter = {
	hidden: {
		opacity: 0,
		y: 100,
		scale: 0.1
	},
	show: {
		opacity: 1,
		y: 0,
		scale: 1,
		transition: {
			type: "spring",
			stiffness: 50,
			delay: 1.2,
		}
	}
}