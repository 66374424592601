import {motion} from "framer-motion";


export const CardService = (props: {
    title: string,
    body: string,
    isLeftImage: boolean,
    patternImage: any,
    mainImage: any,
    icon: any,
}) => {

    const moveTopDown = {
        hidden: {opacity: 0},
        show: {
            opacity: [1, 0.7, 1],
            y: ["0%", "35%", "0%"],
            transition: {
                duration: 10,
                ease: "easeInOut",
                repeat: Infinity,
            }
        }
    }

    const moveRightLeft = {
        hidden: {opacity: 0},
        show: {
            opacity: [1, 0.7, 1],
            x: ["0%", "20%", "-20%", "0%"],
            transition: {
                duration: 14,
                ease: "easeInOut",
                repeat: Infinity,
            }
        }
    }

    return (
        <article
            data-aos="zoom-out-up"
            data-aos-duration="1000"
            data-aos-easing="linear"
            data-aos-offset="0"
            data-aos-anchor-placement="top-bottom"
            data-aos-mirror="true"
            data-aos-once="false"
            className="p-5 font-['Tajawal'] border-2 border-white/10 bg-slate-900/30 rounded-b-xl rounded-l-xl shadow shadow-xl shadow-black/90 relative text-slate-300">

            <h1 className="absolute -top-10 md:-top-11 -right-0.5 flex items-center bg-purple-800/30 rounded-t-xl pt-1 pb-1 px-2 md:px-8 border-x-2 border-t-2 border-white/10 text-xl font-bold md:text-2xl text-slate-300">
                <span>
                    {
                        props.title
                    }
                </span>
                {
                    props.icon
                }
            </h1>

            <div
                className={`relative h-fit flex flex-col flex-col-reverse sm:flex-row ${props.isLeftImage ? "" : "sm:flex-row-reverse"} items-center justify-between xl:justify-around text-right font-medium text-md md:text-xl leading-loose`}>

                <p className="max-w-sm xl:max-w-md leading-loose text-white/90">
                    {
                        props.body
                    }
                </p>

                {/* Pattern image */}
                <img src={props.patternImage}
                     className={`opacity-20 h-32 absolute top-2 ${props.isLeftImage ? 'left-0' : 'right-0'} `}
                     alt="pattern dots"></img>

                {/* 645 For Screen md */}
                {/* Main image */}
                <motion.img src={props.mainImage}
                            onClick={() => {
                                console.log(window.innerWidth)
                            }}
                            variants={window.innerWidth <= 645 ? moveRightLeft : moveTopDown}
                            initial="hidden"
                            animate="show"
                            className={`h-32 mb-5 md:mb-0`}
                            alt="pattern dots"/>
            </div>
        </article>

    );
}