export const RightToCenter = {
	hidden: {
		opacity: 0,
		x: 200,
		scale: 0.1
	},
	show: {
		opacity: 1,
		x: 0,
		scale: 1,
		transition: {
			type: "spring",
			stiffness: 70,
			delay: 0.7,
		}
	}
}