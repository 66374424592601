import {MobileFooter} from "./MobileFooter";
import {OtherFooter} from "./OtherFooter";

export const Footer = () => {
	return (
		<div>
			{/* footer  in other screen */}
			<MobileFooter/>


			{/* footer in all screens */}
			<OtherFooter/>
		</div>
	);
}