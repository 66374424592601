import {SiBuymeacoffee, SiFacebook, SiLinkedin} from "react-icons/si";
import {AiFillInstagram} from "react-icons/ai";

export const OtherFooter = () => {
    return (
        <footer
            className="footer footer-center p-10 bg-purple-700/80 text-primary-content overflow-x-hidden overflow-y-hidden">
            <div
                data-aos="fade-up"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-duration="2000">
                <p className="font-extrabold text-2xl">
                    <a href="https://m7mmadomar.parmage.com" target="_blank"
                       className="hover:text-slate-800 focus:text-slate-800 underline underline-offset-8 decoration-2 decoration-slate-800 transition duration-300 ease-in"
                       rel="noreferrer">
                        M7MMAD OMAR
                    </a>
                    <strong>&nbsp;-&nbsp;</strong>
                    <a href="https://parmage.com" target="_blank"
                       className="hover:text-slate-800 focus:text-slate-800 underline underline-offset-8 decoration-2 decoration-slate-800 transition duration-300 ease-in"
                       rel="noreferrer">
                        PARMAGE TECH</a></p>
                <p>Copyright © &nbsp;
                    {
                        new Date().getFullYear()
                    }
                    &nbsp;
                    <a href="https://m7mmadomar.parmage.com" target="_blank" rel="noreferrer">
                        M7MMAD OMAR
                    </a>
                </p>
            </div>


            <div>
                {/* contact icons */}
                <article>
                    <div className="mb-10">
                        <article className="flex flex-row justify-center">

                            {/*<div className="tooltip" data-tip="Buy Me A Coffee">*/}
                            <a href="https://www.buymeacoffee.com/m7mmadomar" target="_blank" rel="noreferrer">
                                <SiBuymeacoffee
                                    className=" w-10 h-10 mx-2 duration-300 ease-in hover:scale-125 focus:scale-125 hover:rotate-12"/>
                            </a>
                            {/*</div>*/}
                            
                            {/*<div className="tooltip" data-tip="Follow Us">*/}
                                <a href="https://www.instagram.com/m7mmad_0mar" target="_blank" rel="noreferrer">
                                    <AiFillInstagram className=" w-10 h-10 mx-2 duration-300 ease-in hover:scale-125 focus:scale-125 hover:rotate-12"/>
                                </a>
                            {/*</div>*/}

                            {/*<div className="tooltip" data-tip="Follow Us">*/}
                                <a href="https://www.facebook.com/m7mmad.omar" target="_blank" rel="noreferrer">
                                    <SiFacebook className="w-9 h-9 mx-2 duration-300 ease-in hover:scale-125 focus:scale-125 hover:rotate-12"/>
                                </a>
                            {/*</div>*/}

                            {/*<div className="tooltip" data-tip="Follow Us">*/}
                                <a href="https://www.linkedin.com/in/m7mmad-omar" target="_blank" rel="noreferrer">
                                    <SiLinkedin
                                        className=" rounded-xl w-9 h-9 mx-2 duration-300 ease-in hover:scale-125 focus:scale-125 hover:rotate-12"/>
                                </a>
                            {/*</div>*/}
                        </article>
                    </div>
                </article>

            </div>
        </footer>
    );
}