import '../../index.css';
import {SkillDash} from "./SkillDash";
import {SkillCard} from "./SkillCard";

export const PersonalSkills = () => {
    return (
        <div className="relative select-none">
            <h2 className="text-center font-bold text-4xl underline underline-offset-6 decoration-purple-500 mt-20 ">
                المهارات الشخصية
            </h2>

            <section className=" p-2">
                <span className="hidden xl:block absolute -top-32 right-20 bg-skillsPersonal opacity-20 mx-auto mt-20">
                </span>

                <div className="pt-14 text-xl font-medium leading-9 flex justify-center flex-wrap">

                    <SkillCard
                        title="اللغة الإنكليزية"
                        totalSkills="60%"
                        skillsDash={
                            <div className="flex space-x-2" >
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="اللغة العربية"
                        subTitle="اللغة الأم"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>


                    <SkillCard
                        title="العمل الفردي"
                        totalSkills="100%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={true} tipTitle="20%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="العمل مع الفريق"
                        totalSkills="100%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={true} tipTitle="20%"/>
                            </div>
                        }/>


                    <SkillCard
                        title="حل المشكلات"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>


                    <SkillCard
                        title="إدارة الوقت"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>


                    <SkillCard
                        title="Single"
                        totalSkills="100%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={true} tipTitle="20%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="تطوير الذات"
                        totalSkills="100%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={true} tipTitle="20%"/>
                            </div>
                        }/>

                </div>
            </section>
        </div>
    );
}