import {BsDashLg} from "react-icons/bs";

export const SkillDash = ( props: {
    delayDash: any;
    active: any;
    tipTitle: any;
    
}) => {
    return (
        <div className="tooltip" data-tip={props.tipTitle}>
                            <span className={props.active ? "text-green-400" : "text-slate-800"}
                                  data-aos="fade-up"
                                  data-aos-offset="50"
                                  data-aos-easing="linear"
                                  data-aos-duration="2200"
                                  data-aos-delay={props.delayDash}>
                            <BsDashLg className="font-extrabold text-4xl shadow-2xl shadow-black/80"/>
                            </span>
        </div>
    );
}
