import {SiBuymeacoffee, SiFacebook, SiGithub, SiLinkedin} from "react-icons/si";
import {AiFillInstagram} from "react-icons/ai";
import {motion} from "framer-motion";
import {BottomToCenterAndRotate} from "./effect/BottomToCenterAndRotate";

export const ContactLinks = () => {

    // bToCAndR =>  BottomToCenterAndRotate
    const bToCAndR = BottomToCenterAndRotate;

    return (
        <article className="flex flex-row justify-center md:space-x-10 w-full ">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <motion.a href="https://www.buymeacoffee.com/m7mmadomar" target="_blank"
                      variants={bToCAndR}
                      initial="hidden"
                      animate="show">
                <div className="tooltip" data-tip="Support Me">
                    <SiBuymeacoffee
                        className="text-yellow-500 w-10 h-10 md:ml-10 duration-300 ease-in hover:scale-150 focus:scale-150"/>
                </div>
            </motion.a>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <motion.a href="https://www.instagram.com/m7mmad_0mar" target="_blank"
                      variants={bToCAndR}
                      initial="hidden"
                      animate="show"
            >
                <div className="tooltip" data-tip="Follow Me">
                    <AiFillInstagram
                        className="text-fuchsia-600 z-0  w-10 h-10 mx-2 duration-300 ease-in hover:scale-150 focus:scale-150"/>
                </div>
            </motion.a>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <motion.a href="https://www.facebook.com/m7mmad.omar" target="_blank"
                      variants={bToCAndR}
                      initial="hidden"
                      animate="show"
            >
                <div className="tooltip" data-tip="Follow Me">
                    <SiFacebook
                        className="text-blue-700 w-9 h-9 mx-2 duration-300 ease-in hover:scale-150 focus:scale-150"/>
                </div>
            </motion.a>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <motion.a href="https://github.com/M7MMAD-OMAR" target="_blank"
                      variants={bToCAndR}
                      initial="hidden"
                      animate="show"
            >
                <div className="tooltip" data-tip="Follow Me">
                    <SiGithub
                        className="text-black rounded-full  w-9 h-9 mx-2 duration-300 ease-in hover:scale-150 focus:scale-150"/>
                </div>
            </motion.a>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <motion.a href="https://www.linkedin.com/in/m7mmad-omar" target="_blank"
                      variants={bToCAndR}
                      initial="hidden"
                      animate="show"
            >
                <div className="tooltip" data-tip="Follow Me">
                    <SiLinkedin
                        className="text-blue-600 rounded-xl  w-9 h-9 mx-2 duration-300 ease-in hover:scale-150 focus:scale-150"/>
                </div>
            </motion.a>
        </article>
    );
}