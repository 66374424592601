import {motion} from "framer-motion";


export const GreenX = () => {

	const draw = {
		hidden: {pathLength: 0, opacity: 0},
		visible: (i: number) => {
			const delay = 1 + i * 1;
			return {
				pathLength: 1,
				opacity: 1,
				transition: {
					pathLength: {delay, type: "spring", duration: 1, bounce: 0},
					opacity: {delay, duration: 1}
				}
			};
		}
	};

	return (
		<>
			<motion.svg
				height="100%"
				viewBox="0 0 600 600"
				initial="hidden"
				animate="visible"
				className="blur-md w-full"
			>
				<motion.line
					x1="220"
					y1="2"
					x2="400"
					y2="170"
					stroke="#00cc88"
					variants={draw}
					custom={2}
				/>
				<motion.line
					x1="220"
					y1="170"
					x2="400"
					y2="2"
					stroke="#00cc88"
					variants={draw}
					custom={2.5}
				/>
			</motion.svg>
		</>
	);
}