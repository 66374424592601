import CardScroll from "../components/projects/CardScroll";
import "../components/projects/CardProject.css";
import {motion, useScroll, useSpring} from "framer-motion";

// 1_1 => first project and first photo
import img1_1 from "../images/Project/project1/portfolio_2.jpeg";
import img1_2 from "../images/Project/project1/portfolio_1.jpeg";
import img2_1 from "../images/Project/project2/azg_1.jpeg";
import img2_2 from "../images/Project/project2/azg_2.jpeg";
import img2_3 from "../images/Project/project2/azg_3.jpeg";
import img3_1 from "../images/Project/project3/movice_1.png";
import img3_2 from "../images/Project/project3/movice_2.png";
import img3_3 from "../images/Project/project3/movice_3.png";
import img4_1 from "../images/Project/project4/nahda_1.jpeg";
import img4_2 from "../images/Project/project4/nahda_2.jpeg";
import img4_3 from "../images/Project/project4/nahda_3.jpeg";
import img5_1 from "../images/Project/project5/portfolio1_1.jpeg";
import img5_2 from "../images/Project/project5/portfolio1_2.jpeg";
import img6_1 from "../images/Project/project6/Django_1.png";
import img6_2 from "../images/Project/project6/Django_2.png";
import img6_3 from "../images/Project/project6/Django_3.png";
import img7_1 from "../images/Project/project7/project_1.jpg";
import img7_2 from "../images/Project/project7/project_2.jpg";


// images projects
const images1 = [
    img1_1,
    img1_2,
];

const images2 = [
    img2_1,
    img2_2,
    img2_3,
];

const images3 = [
    img3_1,
    img3_2,
    img3_3,
];

const images4 = [
    img4_1,
    img4_2,
    img4_3,
];

const images5 = [
    img5_1,
    img5_2,
];

const images6 = [
    img6_1,
    img6_2,
    img6_3,
];

const images7 = [
    img7_1,
    img7_2,
];


const Projects = () => {

    const {scrollYProgress} = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    return (
        <div className={`bg-projects select-none`}>

            <CardScroll index={1}
                        image={images1}
                        title={`سيرة ذاتية (إلكترونية)`}
                        body={`
                               وهي هذا الموقع الرائع, حيث به خبراتي ومشاريعي البرمجية ومعلوماتي الشخصية
                               فهو شبيه للسيرة الذاتية الورقية مع القليل من لمساتي الفنية
                         
                        `}
                        link={`https://m7mad.uniselector.com`}
            />


            <CardScroll index={2}
                        image={images2}
                        title={`منصة لتداول العملات الرقمية`}
                        body={`
                              منصة إلكترنية تستخدم السحب والإيداع للعمولات الرقمية, 
                              يوجد اشتراكات متنوعة تناسب جميع المستخدمين, المشروع ليس مجرد كلام بل يمكنكم الإطلاع عليه الآن
                        `}
                        link={`https://a-z-g.uniselector.com`}
            />
            <CardScroll index={3}
                        image={images3}
                        title={`إدارة أفلام ومسلسلات`}
                        body={`
                            عبارة عن موقع لعرض الأفلام والمسلسلات وتفاصيل عنهم أيضا يمكن إدخال تصنيفات واسماء الممثلين,
                            طبعا كل هذا العمل بشكل مجاني فهو مشروع مفتوح المصدر  
                        `}
                        link={`https://movies.parmage.com/`}

                        linkGitHub={`https://github.com/M7MMAD-OMAR/Final_Project`}
            />

            <CardScroll index={4}
                        image={images4}
                        title={`مبادرة مفاضلتي`}
                        body={`
                        موقع عام لفريق تطوعي داخل الشمال السوري يسمى (صناع النهضة). يحتوي الموقع على شرح للجامعات والتخصصات 
                        مع إيجاد الفرع المناسب وفق معدل الطالب
                        `}

                        link={`https://nahda-makers.com`}
            />

            <CardScroll index={5}
                        image={images5}
                        title={`سيرة ذاتية إلكترونية`}
                        body={`
                            السيرة الذاتية الخاصة بي أيضا تحتوي على خبراتي وبعض الخدمات الإلكترونية الرائعة
                            والجميل في هذا أن الموقع الآن أصبح مشروع مفتوح المصدر, بالتوفيق
                        `}
                        link={`https://m7mmad-omar.github.io/Personal_Portfolio/`}
                        linkGitHub={`https://github.com/M7MMAD-OMAR/Personal_Portfolio`}
            />

            <CardScroll index={6}
                        image={images6}
                        title={`نظام إدارة سجن`}
                        body={`
                           مستخدما Django لإنشاء نظام إدارة سجناء من حيث الإضافة, التعديل, الحذف....
                            "مشروع جامعي في السنة الثانية" وهو مفتوح المصدر
                            `}

                        linkGitHub={`https://github.com/M7MMAD-OMAR/Django_project_for_prisoner_management`}
            />

            <CardScroll index={7}
                        image={images7}
                        title={`نظام إدارة صيدلية`}
                        body={`
                               برنامج سطح مكتب لل Windows 
                              لإدارة المنتجات من حيث الإضافة والحذف وعرض المنتجات وغيره من المميزات الجميلة..
                               لا تنسى الإطلاع عليه من خلال رابط الـ GitHub
                            `}

                        linkGitHub={`https://github.com/M7MMAD-OMAR/Pharmacy_project`}
            />

            <motion.div className="fixed left-0 right-0 h-3 bg-purple-800/60 bottom-[100px] rounded-full"
                        style={{scaleX}}/>
        </div>
    );
};

export default Projects;