import {Link} from "react-router-dom";

export const NotFound = () => {
    return (
        <div className={`min-h-screen flex flex-col justify-center items-center text-8xl font-bold text-slate-300`}>
            <code> 🥲 404 🥲 </code>

            <Link to={`/`}>
                <p className={`py-10 text-purple-500`}>
                    Home Page
                </p>
            </Link>
        </div>
    );
}