import {Link} from "react-router-dom";


export const Header = () => {


    return (
        <div className={`relative`}>
            <header className="navbar relative bg-black/40  shadow-2xl shadow-black/80 z-50">
                <p className="font-bold text-xl sm:font-extrabold sm:text-2xl mx-auto">

                    <a
                        data-aos="fade-down"
                        data-aos-duration="2000"
                        href="https://parmage.com" target="_blank"
                        className="hover:text-purple-800 focus:text-slate-800 underline underline-offset-8 decoration-2 decoration-purple-800/70 transition duration-300 ease-in"
                        rel="noreferrer">
                        PARMAGE TECH</a>
                    <strong>&nbsp;-&nbsp;</strong>
                    <Link to="/" target="_blank"
                          className="hover:text-purple-800 focus:text-slate-800 underline underline-offset-8 decoration-2 decoration-purple-800/70 transition duration-300 ease-in"
                          rel="noreferrer">
                        <p
                            data-aos="fade-down"
                            data-aos-duration="2000">
                            M7MMAD OMAR
                        </p>
                    </Link>


                </p>


            </header>

            <a
                href="https://drive.google.com/drive/folders/1cv8_LVdGzum_R_qy5eIOqdSqAZEjAmAP?usp=share_link"
                target="_blank" rel="noreferrer"
                className={`absolute right-10 z-50 top-20 md:top-3`}>
                <button
                    className={`py-2 px-3 flex items-center justify-center bg-purple-800/50 text-slate-300 font-bold rounded-xl hover:bg-purple-900 focus:bg-purple-900`}>
                    CV
                    {/*<FileDownloadOutlinedIcon/>*/}
                </button>
            </a>
        </div>
    );
}