import {motion} from "framer-motion";


export const RedCircle = () => {

	const draw = {
		hidden: {pathLength: 0, opacity: 0},
		visible: (i: number) => {
			const delay = 1 + i * 0.7;
			return {
				pathLength: 1,
				opacity: 1,
				transition: {
					pathLength: {delay, type: "spring", duration: 3.5, bounce: 0},
					opacity: {duration: 3}
				}
			};
		}
	};

	return (
		<>
			<motion.svg
				viewBox="0 0 600 600"
				initial="hidden"
				animate="visible"
				className="absolute top-10 blur-md w-full "
			>
				<motion.circle
					cx="570"
					cy="500"
					r="80"
					stroke="#a324aa"
					variants={draw}
					custom={3}
				/>
			</motion.svg>


		</>
	);
}