import {useState} from "react";
import {motion} from "framer-motion";
import cat from "../../../images/iconsGif/cat.gif";
import love from "../../../images/iconsGif/love.gif";
import {Li} from "../../Li";
import {FcHome, FcBusinessContact, FcBriefcase, FcPaid, FcBusinessman, FcReading} from 'react-icons/fc';

export const MobileFooter = () => {
    const [isOn, setIsOn] = useState(false);


    const toggleSwitch = () => setIsOn(!isOn);
    const framer = {
        hidden: {
            height: 0,
            opacity: 0,
            delay: 0.2,
        },
        show: {
            height: "66vh",
            opacity: 100,
            transition: {
                type: "spring",
                stiffness: 100,
                delay: 0,
            },
        },
    }


    // @ts-ignore
    return (
        <motion.footer
            className="overflow-y-scroll overflow-x-hidden flex justify-center items-center z-50 select-none">

            {/* footer nav link */}
            {
                isOn ? (
                    <motion.nav
                        className="bg-black/90 pt-8 border border-2 border-white/40 shadow-2xl shadow-black rounded-xl w-[90%]  md:w-1/2 xl:w-1/3 fixed bottom-3 flex ltr:flex-row rtl:flex-row-reverse justify-center"
                        variants={framer}
                        initial="hidden"
                        animate="show">
                        <div className="btn btn-circle absolute -top-6 text-2xl bg-purple-800 "
                             onClick={toggleSwitch}
                        >
                            <motion.span
                                className="tooltip pt-1" data-tip="إغلاق القائمة"
                                whileHover={{scale: 1.5}}
                                whileTap={{scale: 1.5}}>
                                👇
                            </motion.span>
                        </div>

                        <div
                            className="absolute overflow-y-hidden -bottom-2 xl:-bottom-5 w-32 md:w-44  xl:w-64 opacity-60 bg-amber-20 h-24 xl:h-24">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src={cat} alt="Cat Image" className="absolute bottom-0 md:-bottom-2"/>

                        </div>
                        <span className="absolute bottom-0 w-32 md:w-64 border-b border-slate-200"></span>


                        <motion.ul className="w-full" onClick={toggleSwitch}>

                            <Li title="الصفحة الرئيسية" icon={<FcHome/>} link="/"/>

                            <Li title="لمحة تعريفية" icon={<FcBusinessman/>} link="/about"/>

                            <Li title="مؤهلات علمية" icon={<FcReading/>} link="/experience"/>

                            <Li title="مشاريع برمجية" icon={<FcPaid/>} link="/projects"/>

                            <Li title="خدمات إلكترونية" icon={<FcBriefcase/>} link="/services"/>

                            <Li title="معرفات التواصل" icon={<FcBusinessContact/>} link="/contact"/>

                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <Li title="ادعمني" icon={<img src={love} alt="support image" className="w-6"/>}
                                link="/support"/>
                        </motion.ul>


                    </motion.nav>
                ) : (
                    //	{/* footer buttons */}
                    <motion.div
                        className="container bg-black/50 border-2 border-purple-800  rounded-full w-[90%] md:w-1/2 xl:w-1/3 fixed bottom-3 py-2 flex ltr:flex-row rtl:flex-row-reverse justify-center"
                        initial={{opacity: 0.4}}
                        animate={{opacity: 1}}
                        transition={{
                            height: {duration: 3},
                            default: {ease: "easeIn"},
                            opacity: {duration: 1}
                        }}
                    >
                        {/*<button*/}
                        {/*	className="btn btn-xs pb-1  px-4 text-sm absolute bg-purple-700 rounded-full ltr:left-10 rtl:right-10">*/}
                        {/*	<span>رجوع</span>*/}
                        {/*</button>*/}

                        <button data-isOn={isOn} onClick={toggleSwitch}
                                className="btn btn-xs relative px-12 rounded-full bg-slate-400 font-extrabold tooltip text-3xl hover:bg-purple-800 focus:bg-purple-800"
                                data-tip="القائمة">
                            <span className="mx-1.5 w-2 text-black/80 relative bottom-3 md:bottom-3.5">.</span>
                            <span className="mx-1.5 w-2 text-black/80 relative bottom-3 md:bottom-3.5">.</span>
                            <span className="mx-1.5 w-2 text-black/80 relative bottom-3 md:bottom-3.5">.</span>
                        </button>
                    </motion.div>
                )
            }

        </motion.footer>
    );
}