import * as React from "react";
import {useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {wrap} from "popmotion";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 0,
        x: 0,
        opacity: 1
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

// @ts-ignore
export const Slider = ({images}) => {
    const [[page, direction], setPage] = useState([0, 0]);

    // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
    // then wrap that within 0-2 to find our image ID in the array below. By passing an
    // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
    // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
    const imageIndex = wrap(0, images.length, page);

    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    };

    return (
        <div>
            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    className={`absolute -top-64 md:-top-80 rounded-xl shadow-2xl drop-shadow-2xl shadow-black/80`}
                    key={page}
                    src={images[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: {type: "spring", stiffness: 140, damping: 30},
                        opacity: {duration: 0.6}
                    }}
                    drag="x"
                    dragConstraints={{left: 0, right: 0}}
                    dragElastic={1}
                    onDragEnd={(e, {offset, velocity}) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1);
                        }
                    }}
                />
            </AnimatePresence>
            <motion.div
                className="next right-0  -bottom-72 xl:-bottom-64 md:right-10 absolute bg-slate-300 border-2 border-purple-600 rounded-full w-7 h-7 md:w-10 md:h-10 flex justify-center items-center select-none cursor-pointer font-extrabold  text-8xl text-black opacity-80"
                onClick={() => paginate(1)}>
                <ChevronRightIcon/>
            </motion.div>
            <motion.div
                className="next left-0 -bottom-72 xl:-bottom-64  md:left-10 absolute bg-slate-300 border-2 border-purple-600 rounded-full w-7 h-7 md:w-10 md:h-10 flex justify-center items-center select-none cursor-pointer font-extrabold  text-8xl text-black opacity-80"
                onClick={() => paginate(-1)}>
                <ChevronLeftIcon/>
            </motion.div>
        </div>
    );
};
