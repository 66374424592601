import {motion} from "framer-motion";


export const BlueLine1 = () => {

	const draw = {
		hidden: {pathLength: 0, opacity: 0},
		visible: (i: number) => {
			const delay = 1 + i * 0.7;
			return {
				pathLength: 1,
				opacity: 1,
				transition: {
					pathLength: {delay, type: "spring", duration: 3.5, bounce: 0},
					opacity: {duration: 3}
				}
			};
		}
	};

	return (
		<>
			<motion.svg
				viewBox="0 0 600 600"
				initial="hidden"
				animate="visible"
				className="absolute top-1/2 blur-md w-full "
			>
				<motion.line
					x1="40"
					y1="20"
					x2="360"
					y2="570"
					stroke="#3039da"
					variants={draw}
					custom={4}
				/>
				<motion.line
					x1="20"
					y1="600"
					x2="700"
					y2="430"
					stroke="#a324aa"
					variants={draw}
					custom={4.5}
				/>
			</motion.svg>


		</>
	);
}