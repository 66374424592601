import {motion} from "framer-motion";
import {Char} from "../components/Char";
import {Skills} from "../components/skills/Skills";
import "../index.css";

const About = () => {
    const aboutMe: string = `مهندس برمجيات مهتم بـ Laravel Stack Developer, ReactJS, عملت لأكثر من عام ضمن مجال الويب
    مستخدما اللغات البرمجية والخوارزميات الأساسية, لدي خبرة جيدة في مجال تطبيقات الجوال Flutter مع قواعد البيانات بالتأكيد, الوقت قصير جداً😞 `

    return (
        <motion.div
            initial={{y: "100%", opacity: 0}}
            animate={{y: "0%", opacity: 1}}
            transition={{duration: 1.5, ease: "easeOut"}}
            className="min-h-screen pb-32 pt-20 w[90%] xl:pb-10 pattern-about">
            <section
                data-aos="fade-left"
                data-aos-delay="1400"
                data-aos-duration="2000"
                className=" mx-5 bg-purple-500/30 tracking-wide leading-loose rounded-xl font-medium py-4 shadow-2xl shadow-slate-300/10">
                <h3
                    data-aos="fade-left"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-delay="300"
                    data-aos-duration="2500"
                    className="text-xl font-bold text-slate-300 border-r-4 border-purple-500 px-4 py-2 leading-loose">
                    اسمي محمد عمر حاج حمدو
                    <br/>
                    {
                        aboutMe.split('').map((char) => {
                            return <Char char={char}/>
                        })
                    }
                    <p>وهذه بعض مهاراتي المتنوعة 👇👇</p>
                </h3>
            </section>

            {/* start skills section */}
            <Skills/>
            {/* end skills section */}

        </motion.div>
    );
}


export default About;