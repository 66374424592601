import {motion} from "framer-motion";
import customerCare from "../images/iconsGif/customer-care.gif";
import gmailButton from "../images/iconsGif/sending-mail.gif";
import socialMedia from "../images/social-media-influencer.gif";
import whatsapp from "../images/iconsGif/whatsapp.gif"
import {MdLocationOn, MdToday} from "react-icons/md";
import {SocialMediaIcon} from "../components/SocialMediaIcon";
import {FaFacebook, FaGithub, FaInstagram, FaLinkedinIn} from "react-icons/fa";


const Contact = () => {
    const motions = {
        hidden: {
            opacity: 0,
            y: 20,
        },
        show: {
            opacity: 1,
            y: 0,
        }
    }

	return (
		<motion.div
			initial={{y : "100%", opacity: 0}}
			animate={{y : "0%", opacity: 1}}
			transition={{ duration: 0.75, ease: "easeOut"}}>


			<section
				className="bg-black/60 w-[90%] md:w-[80%] rounded-xl mx-auto my-10 mb-32 pb-10 p-5 shadow-2xl shadow-slate-500/20 border-2 border-white/20">

				<div
					className="flex flex-col flex-col-reverse md:flex-row justify-around items-center">

					<article className="flex flex-col space-y-5 min-w-fit w-full sm:w-[30%] ">

						{/* first item */}
						<motion.div
							variants={motions}
							initial="hidden"
							animate="show"
							transition={{delay: 0.8, type: "spring"}}
						>
							<div
								className="container-fuild border-b-2 border-purple-500/40 mx-3 text-slate-300  text-md md:text-xl font-medium px-4 pb-1 ">
								<div className="flex flex-row items-center ">
									{/*<MdEmail className="mx-5 "/>*/}
									<img src={gmailButton} alt="Gmail Button" className="w-8 md:w-12 mx-1 md:mx-4"/>
									<a href="mailto:m7mmad.omar0@gmail.com" target="_blank"
									   className="mt-3 duration-300 ease-linear hover:scale-110 focus:scale-110" rel="noreferrer">
										m7mmad.omar0@gmail.com</a>
								</div>
							</div>
						</motion.div>
						{/* end item */}

						{/* first item */}
						<motion.div
							variants={motions}
							initial="hidden"
							animate="show"
							transition={{delay: 1.4, type: "spring"}}
						>
							<div
								className="container-fuild border-b-2 border-purple-500/40 mx-3 text-slate-300 font-medium px-4 pb-1  ">
								<div className="flex flex-row items-center text-xl flex-wrap">
									{/*<MdPhone className="mx-5 "/>*/}
									<img src={customerCare} alt="WhatsApp Button" className="w-8 md:w-12 mx-1 md:mx-4"/>
									<a href="tel:+905386448699" target="_blank" dir="ltr"
									   className="mt-3 duration-300 ease-linear hover:scale-110 focus:scale-110" rel="noreferrer">
										+90 53 8644 8699
									</a>
								</div>
							</div>
						</motion.div>
						{/* end item */}

						{/* first item */}
						<motion.div
							variants={motions}
							initial="hidden"
							animate="show"
							transition={{delay: 2, type: "spring"}}
						>
							<div
								className="container-fuild border-b-2 border-purple-500/40 mx-3 text-slate-300 font-medium px-4 pb-1  ">
								<div className="flex flex-row items-center text-xl flex-wrap">
									{/*<MdOutlinePermPhoneMsg className="mx-5 "/>*/}
									<img src={whatsapp} alt="WhatsApp Button"
											 className="w-7 md:w-8 mx-2 mr-1 ml-2 md:mx-6"/>
									<a href="https://wa.me/+905386448699" target="_blank" dir="ltr" rel="noreferrer"
									   className="mt-3 duration-300 ease-linear hover:scale-110 focus:scale-110">
										+90 53 8644 8699
									</a>
								</div>
							</div>
						</motion.div>
						{/* end item */}


						{/* first item */}
						<motion.div
							variants={motions}
							initial="hidden"
							animate="show"
							transition={{delay: 2.6, type: "spring"}}
						>
							<div
								className="container-fuild border-b-2 border-purple-500/40 mx-3 text-slate-300 font-medium px-4 pb-1  ">
								<div className="flex flex-row items-center text-xl flex-wrap">
									<MdToday className="w-8 h-8 md:w-12 mr-1 ml-2 md:mx-4"/>
									<span className="mt-3 duration-300 ease-linear hover:scale-110 focus:scale-110">
									15 - 2 - 0000
									</span>
								</div>
							</div>
						</motion.div>
						{/* end item */}

						{/* first item */}
						<motion.div
							variants={motions}
							initial="hidden"
							animate="show"
							transition={{delay: 3.2, type: "spring"}}
						>
							<div
								className="container-fuild border-b-2 border-purple-500/40 mx-3 text-slate-300 font-medium px-4 pb-1  ">
								<div className="flex flex-row items-center text-xl flex-wrap">
									<MdLocationOn className="animate-bounce w-8 h-8 mr-1 ml-2 md:w-12 md:mx-4"/>
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
									<a className="mt-3 duration-300 ease-linear hover:scale-110 focus:scale-110">
										سوريا, حلب, أعزاز</a>
								</div>
							</div>
						</motion.div>
						{/* end item */}


					</article>

					{/*<section>*/}
					<img src={socialMedia} alt="Social Media" className="w-1/2 md:w-1/4 m-8"
						 data-aos="fade-right"
						 data-aos-delay="70"
						 data-aos-offset="200"
						 data-aos-duration="1000"
						 data-aos-easing="ease-in"
					/>
					{/*</section>*/}


				</div>


				{/* Start Contact Icons Social Media */}
				<article className="flex justify-center mt-8 underline border-b-2 border-purple-500/20">


					{/* Start icon instagram */}
					<SocialMediaIcon tipName="m7mmad_0mar" socialUrl="https://www.instagram.com/m7mmad_0mar"
									 iconName={<FaInstagram
										 className="w-7 h-7 mx-3 my-2.5 duration-300 ease-in hover:scale-150 focus:scale-150 cursor-pointer"/>
									 }/>
					{/* End icon instagram */}


					{/* Start icon facebook */}
					<SocialMediaIcon tipName="m7mmad.omar" socialUrl="https://www.facebook.com/m7mmad.omar"
									 iconName={<FaFacebook
										 className="w-7 h-7 mx-3 my-2.5 duration-300 ease-in hover:scale-150 focus:scale-150 cursor-pointer"/>
									 }/>
					{/* End icon facebook */}


					{/* Start icon github */}
					<SocialMediaIcon tipName="M7MMAD-OMAR" socialUrl="https://github.com/M7MMAD-OMAR"
									 iconName={<FaGithub
										 className="w-7 h-7 mx-3 my-2.5 duration-300 ease-in hover:scale-150 focus:scale-150 cursor-pointer"/>
									 }/>
					{/* End icon github */}

					{/* Start icon */}
					<SocialMediaIcon tipName="m7mmad-omar" socialUrl="www.linkedin.com/in/m7mmad-omar"
									 iconName={<FaLinkedinIn
										 className="w-7 h-7 mx-3 my-2.5 duration-300 ease-in hover:scale-150 focus:scale-150 cursor-pointer"/>
									 }/>
					{/* End icon */}


                </article>
                {/* End Contact Icons Social Media */}


            </section>

        </motion.div>
    );
};

export default Contact;