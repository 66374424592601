import {motion} from "framer-motion";


export const RedLine = () => {

	const draw = {
		hidden: {pathLength: 0, opacity: 0},
		visible: (i: number) => {
			const delay = 1 + i * 0.1;
			return {
				pathLength: 1,
				opacity: 1,
				transition: {
					pathLength: {delay, type: "spring", duration: 1.2, bounce: 0},
					opacity: {duration: 0.9}
				}
			};
		}
	};

	return (
		<>
			<motion.svg
				viewBox="0 0 600 600"
				initial="hidden"
				animate="visible"
				className="absolute top-16 blur-md w-full "
			>
				<motion.line
					x1="100"
					y1="300"
					x2="360"
					y2="370"
					stroke="#ff0055"
					custom={3}
					variants={draw}
				/>
				<motion.line
					x1="300"
					y1="100"
					x2="360"
					y2="230"
					stroke="#ff0055"
					custom={3.5}
					variants={draw}
				/>
			</motion.svg>


		</>
	);
}