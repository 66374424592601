import {motion} from "framer-motion";


export const SocialMediaIcon = (props : {
    iconName: any;
    socialUrl: any;
    tipName: any;
    
}) => {
    return (
        <motion.div
            animate={{
                scale: [1, 1.4, 1],
                rotate: [0, 30, -30, 0],
            }}
            transition={{
                duration: 2,
                ease: "easeInOut",
                times: [0.4, 1, 1.8, 2, 0.4],
                repeat: Infinity,
                repeatDelay: 1
            }}
        >
            <div className="tooltip" data-tip={props.tipName}>
                <a href={props.socialUrl} target="_blank" rel="noreferrer">
                    {props.iconName}
                </a>
            </div>
        </motion.div>
    );
}