const Support = () => {
    return (
        <div className={`min-h-screen flex justify-center pt-32`}>

            <div className={`text-8xl font-bold text-center `}>
                <p className={`py-2`}>❤️ ❤️</p>
                <p>Thanks Me</p>
                <p className={`pb-4`}>و</p>
                <p>Thanks You</p>

            </div>

        </div>
    );
};

export default Support;