import "./CardProject.css";
import {useRef} from "react";
import {
    motion,
    useScroll,
    useTransform,
    MotionValue
} from "framer-motion";
import {Slider} from "../sliderImage/Slider";


function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance]);
}

function Image({
                   id,
                   images,
                   title,
                   body,
                   link,
                   linkGitHub
               }: { id: number, images: any, title: string, body: string, link?: string, linkGitHub?: string }) {
    const ref = useRef(null);
    const {scrollYProgress} = useScroll({target: ref});
    const y = useParallax(scrollYProgress, 190);

    return (
        <section
            className={`card-project w-[98vw] sm:w-[80vw] xl:w-[60vw] flex justify-center items-center relative mx-auto h-[100vh] pt-32 overflow-y-hidden overflow-x-hidden `}>
            <div>
                <div className={`relative w-[280px]  md:w-[380px] mx-auto`}>
                    <div className={`absolute left-0 right-0 bottom-0 h-[50%]`}>
                        <Slider images={images}/>
                    </div>
                </div>

                <div className={`pr-10 md:pr-12 xl:pr-24`}>
                    <h2 className={`text-2xl xl:text-4xl font-bold text-slate-300 my-3 pr-2 border-r-4 border-purple-800 rounded-sm`}>
                        {title}
                    </h2>

                    <p className={`text-xl xl:text-2xl font-medium text-slate-300 leading-loose`}>
                        {body}
                    </p>
                </div>
                <p className={`flex justify-center underline underline-offset-4 py-2 ${link ? "block" : "hidden"}`}>
                    <a href={link} className={`text-xl text-purple-500`}>الذهاب للموقع</a>
                </p>
                <p className={`flex justify-center underline underline-offset-4 py-2 ${linkGitHub ? "block" : "hidden"}`}>
                    <a href={linkGitHub} className={`text-xl text-purple-500`}>GitHub</a>
                </p>
            </div>
            <motion.h2
                className={`text-slate-100 text-2xl md:text-3xl xl:text-5xl font-bold absolute right-0 `}
                style={{y}}>{`#0${id}`}
            </motion.h2>
        </section>
        // <section
        //     className={`card-project h-[100vh] flex justify-center items-center relative`}>
        //
        //     <div
        //         className={`w-[100vw] md:w-[60vw] h-[400px] relative max-h-[90vh] m-[20px] bg-slate-300 rounded-xl overflow-hidden`}
        //         ref={ref}>
        //         {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        //         <img src={image} alt="Project Image"
        //              className={`absolute top-0 left-0 right-0 bottom-0 w-full h-[50%]`}/>
        //     </div>
        //     <motion.h2
        //         className={`number-project m-0 text-slate-100 text-5xl font-bold absolute md:left-52`}
        //         style={{y}}>{`#0${id}`}
        //     </motion.h2>
        // </section>
    );
}

export default function CardScroll(props: {
    index: number,
    image: any,
    title: string,
    body: string,
    link?: string,
    linkGitHub?: string,
}) {

    return (
        <div>
            <Image id={props.index} images={props.image} title={props.title} body={props.body} link={props.link}
                   linkGitHub={props.linkGitHub}/>
        </div>
    );
}
