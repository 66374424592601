export const BottomToCenterAndRotate = {
	hidden: {
		opacity: 0,
		y: 200,
		scale: 0.1,
		rotate: 0,
	},
	show: {
		opacity: 1,
		y: 0,
		scale: 1,
		rotate: 360,
		transition: {
			type: "spring",
			stiffness: 20,
			duration: "easeOutIn",
			delay: 1.8,
		}
	}
}