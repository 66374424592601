import {RedO} from "./drawingShapes/RedO";
import {GreenX} from "./drawingShapes/GreenX";
import {BlueSquare} from "./drawingShapes/BlueSquare";
import {BlueO} from "./drawingShapes/BlueO";
import {RedLine} from "./drawingShapes/RedLine";
import {GreenSquare} from "./drawingShapes/GreenSquare";
import {RedCircle} from "./drawingShapes/RedCircle";
import {BlueLine1} from "./drawingShapes/BlueLine1";

export const DrawInHomePage = () => {


    return (
        <div className="md:hidden">
            <RedO/>
            <GreenX/>
            <BlueSquare/>
            <BlueO/>
            <RedLine/>
            <GreenSquare/>
            <RedCircle/>
            <BlueLine1/>
        </div>
    );
}