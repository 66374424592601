import '../../index.css';
import {SkillDash} from "./SkillDash";
import {SkillCard} from "./SkillCard";

export const ScientificSkills = () => {
    return (
        <div className="relative select-none">

            <h2 className="text-center font-bold text-4xl underline underline-offset-6 decoration-purple-500 mt-20 ">
                المهارات العلمية
            </h2>

            <section className=" p-2">
                <span className="hidden xl:block absolute top-0 right-10 bg-skills opacity-20 mx-auto mt-20">
                </span>

                <div dir="ltr" className=" pt-14 text-xl font-medium leading-9 text-left flex justify-center flex-wrap">


                    <SkillCard
                        title="Laravel"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>


                    <SkillCard
                        title="ReactJS"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="JavaScript"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="PHP"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Tailwind CSS"
                        totalSkills="60%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Framer Motion"
                        totalSkills="60%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Bootstrap"
                        totalSkills="60%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>


                    <SkillCard
                        title="Algorithms"
                        totalSkills="60%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Data Analysis"
                        totalSkills="40%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Integrated Db"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="WordPress"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Git"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="GitHub"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>


                    <SkillCard
                        title="Flutter"
                        totalSkills="60%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>


                    <SkillCard
                        title="Dart"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Python"
                        totalSkills="40%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Django"
                        totalSkills="20%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={700} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="MongoDB"
                        totalSkills="60%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>


                    <SkillCard
                        title="SQL Server"
                        totalSkills="60%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>


                    <SkillCard
                        title="JAVA"
                        totalSkills="20%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={700} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Office"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Illustrator"
                        totalSkills="80%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>

                    <SkillCard
                        title="Photoshop"
                        totalSkills="60%"
                        skillsDash={
                            <div className="flex space-x-2">
                                <SkillDash delayDash={500} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={600} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={700} active={true} tipTitle="20%"/>
                                <SkillDash delayDash={800} active={false} tipTitle="0%"/>
                                <SkillDash delayDash={900} active={false} tipTitle="0%"/>
                            </div>
                        }/>


                </div>
            </section>


        </div>
    );
}